/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
//import { useState } from 'react';
import {
  useDataGrid,
  //EditButton,
  ShowButton,
  //DeleteButton,
  List,
  MarkdownField,
  DateField,
} from "@refinedev/mui";
import { EditButton } from "components/editButton";
import {
  DataGrid,
  GridColDef,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridRowCountSelector,
  gridPageSizeSelector,
} from "@mui/x-data-grid";
import { IResourceComponentsProps, useList, HttpError } from "@refinedev/core";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "lib/ui/mui/Box";
import Typography from "lib/ui/mui/Typography";
import StatusChip from "lib/ui/core/StatusChip";
import Card from "lib/ui/mui/Card";
import {
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  MenuItem,
  IconButton,
  FormControl,
  Select,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { statusColorMapping } from "constant/color-mapping";

const pageSizeOptions = [10, 25, 50, 100];

const TitleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  paddingBottom: "30px",
  alignItems: "center",
  width: "100%",
}));

const Content = styled(Card)(({ theme }) => ({}));

const FooterItem = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "right",
  width: "33%",
  padding: "10px",
}));

const FooterText = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  width: "100%",
  "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
    borderRight: "1px solid #f0f0f0",
    paddingLeft: "20px",
  },
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "#f9fafb",
    borderTop: "2px solid #f0f0f0",
  },
  "& .MuiDataGrid-cellContent": {
    //overflow: "auto",
    //textOverflow: "initial",
  },
  "& .Bold-Colomn": {
    fontWeight: "bold",
  },
}));

export interface ExtendedIResourceComponentsProps
  extends IResourceComponentsProps {
  setIds?: any;
  statusFilter?: string;
  setStatusFilter?: any;
  searchFilter?: string;
  setSearchFilter?: any;
  campaignFilter?: string;
  setCampaignFilter?: any;
  pageCurrent?: number;
  setPageCurrent?: any;
  pageSize?: number;
  setPageSize?: any;
  sortField?: string;
  setSortField?: any;
  sortOrder?: string;
  setSortOrder?: any;
}

export const BlogPostList: React.FC<ExtendedIResourceComponentsProps> = ({
  setIds,
  statusFilter,
  setStatusFilter,
  searchFilter,
  setSearchFilter,
  campaignFilter,
  setCampaignFilter,
  pageCurrent,
  setPageCurrent,
  pageSize,
  setPageSize,
  sortField,
  setSortField,
  sortOrder,
  setSortOrder,
}) => {
  const { dataGridProps, setFilters } = useDataGrid({
    pagination: {
      pageSize: pageSize,
      current: pageCurrent,
    },
    ...(sortField && sortOrder && (sortOrder === "asc" || sortOrder === "desc")
      ? {
          sorters: {
            initial: [
              {
                field: sortField,
                order: sortOrder,
              },
            ],
          },
        }
      : {}),
  });

  /*  const { data: categoryData, isLoading: categoryIsLoading } = useMany({
        resource: "categories",
        ids: dataGridProps?.rows?.map((item: any) => item?.category?.id) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    }); */

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param = {
    current: queryParams.get("current"),
    pageSize: queryParams.get("pageSize"),
  };

  useEffect(() => {
    setPageCurrent(param.current);
    setPageSize(param.pageSize);
  }, [param.current, param.pageSize]);

  const columns = React.useMemo<GridColDef[]>(
    () => [
      /* {
                field: "id",
                headerName: "Id",
                type: "number",
                minWidth: 50,
            }, */
      {
        field: "merchantName",
        flex: 1,
        headerName: "Merchant Name",
        cellClassName: "Bold-Colomn",
        minWidth: 150,
      },
      {
        field: "No",
        flex: 1,
        headerName: "Receipt No.",
        minWidth: 50,
      },
      {
        field: "amount",
        flex: 1,
        headerName: "Amount",
        minWidth: 80,
        renderCell: function render({ value }) {
          return <MarkdownField value={(value ?? "").slice(0, 30)} />;
        },
      },
      {
        field: "paymentDate",
        flex: 1,
        headerName: "Payment Date",
        minWidth: 80,
        renderCell: function render({ value }) {
          if (!value) {
            return <Typography>-</Typography>;
          }
          return <DateField value={value} />;
        },
        /* valueGetter: ({ row }) => {
                    const value = row?.category?.id;

                    return value;
                },
                minWidth: 200,
                renderCell: function render({ value }) {
                  return categoryIsLoading ? (
                      <>Loading...</>
                  ) : (
                      categoryData?.data?.find((item) => item.id === value)
                          ?.title
                  );
                }, */
      },
      /* {
                field: "status",
                flex: 1,
                headerName: "Status",
                minWidth: 100,
            }, */
      {
        field: "createdAt",
        flex: 1,
        headerName: "Submission Date",
        minWidth: 80,
        renderCell: function render({ value }) {
          if (!value) {
            return <Typography>-</Typography>;
          }
          return <DateField value={value} />;
        },
      },
      {
        field: "email",
        flex: 1,
        headerName: "email",
        //minWidth: 200,
        maxWidth: 200,
      },
      {
        field: "status",
        flex: 1,
        headerName: "Status",
        minWidth: 150,
        renderCell: function render({ value }) {
          const { textColor, backgroundColor } =
            statusColorMapping[value] || statusColorMapping["Default"];
          return (
            <StatusChip
              label={value}
              textcolor={textColor}
              backgroundcolor={backgroundColor}
              icon={
                <FiberManualRecordIcon
                  style={{ fontSize: "8px", color: textColor }}
                />
              }
            />
          );
        },
      },
      {
        field: "campaign",
        flex: 1,
        headerName: "Campaign",
        sortable: false,
        disableColumnMenu: true,
        valueFormatter: (campaign: any) => {
          return campaign.value.name;
        },
      },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton
                IconComponent={EditOutlined}
                hideText
                recordItemId={row.id}
                resource="receipt"
              />
              {/* <ShowButton hideText recordItemId={row.id} /> */}
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    /* [categoryData?.data, categoryIsLoading] ,*/
    []
  );

  const [searchBarValue, setSearchBarValue] = useState(searchFilter);

  useEffect(() => {
    setFilters([
      {
        field: "status",
        value: statusFilter ? statusFilter : undefined,
        operator: "eq",
      },
      {
        field: "searchString",
        value: searchFilter ? searchFilter : undefined,
        operator: "eq",
      },
      {
        field: "campaignId",
        value: campaignFilter ? campaignFilter : undefined,
        operator: "eq",
      },
    ]);
    const fetchReceiptIds = async () => {
      // set receipt ids
      const params: any = {};
      if (statusFilter) {
        params.status = statusFilter;
      }
      if (campaignFilter) {
        params.campaignId = campaignFilter;
      }
      if (searchFilter) {
        params.searchString = searchFilter;
      }
      if (dataGridProps?.sortModel?.[0]?.field) {
        params._sort = dataGridProps?.sortModel?.[0]?.field;
      }
      if (dataGridProps?.sortModel?.[0]?.sort) {
        params._order = dataGridProps?.sortModel?.[0]?.sort;
      }
      const u = new URLSearchParams(params).toString();
      const { data } = await axios.get(
        `${process.env.REACT_APP_DATAPROVIDER_URL}/receiptIds?${u}`
      );
      setIds(data);
      // const { data:paymentList} = await axios.get(
      //   `${process.env.REACT_APP_DATAPROVIDER_URL}/paymentMethods`
      // );
      // console.log('temp',paymentList);
    };
    fetchReceiptIds();
  }, [
    statusFilter,
    searchFilter,
    campaignFilter,
    dataGridProps?.sortModel?.[0]?.field,
    dataGridProps?.sortModel?.[0]?.sort,
  ]);

  useEffect(() => {
    setSortField(dataGridProps?.sortModel?.[0]?.field);
    setSortOrder(dataGridProps?.sortModel?.[0]?.sort);
  }, [
    dataGridProps?.sortModel?.[0]?.field,
    dataGridProps?.sortModel?.[0]?.sort,
  ]);

  const handleStatusFilter = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    let value = newAlignment;
    setStatusFilter(value);
  };

  const onSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    
    setSearchBarValue(value);
    if(value===''){
      // setSearchBarValue(e=>e)
      // console.log('onSearchValueChange:',value);
      // handleSearchFilter();
      setSearchFilter('');
    }
  };

  const handleSearchFilter = () => {
    setSearchFilter(searchBarValue);
    // console.log('handleSearchFilter:',searchBarValue);
  };

  const onCampaignChange = (event: SelectChangeEvent) => {
    setCampaignFilter(event.target.value);
  };

  const statusRadioOptions = [
    { label: "View All", value: "" },
    { label: "In-Review", value: "In-Review" },
    { label: "Approved", value: "Approved" },
    { label: "Pre-Approved", value: "Pre-Approved" },
    { label: "Rejected", value: "Rejected" },
    /* {label: "draft", value: 'draft'}, */
  ];

  interface ICampaign {
    id: number;
    name: string;
    description: string;
  }

  const { data, isLoading, isError } = useList<ICampaign, HttpError>({
    resource: "campaigns",
  });
  const campaigns = data?.data ?? [];

  // const onDownload = async () => {
  //   try {
  //     const reqBody = campaignFilter ? { campaignId: campaignFilter } : {};
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_DATAPROVIDER_URL}/csv`,
  //       reqBody
  //     );
  //     console.log('csv',response.data);
  //     // Handle the response data, such as updating state or displaying a success message

  //     const a = document.createElement("a");
  //     a.textContent = "download";
  //     a.download = "receipts.csv";
  //     a.href = "data:text/csv;charset=utf-8," + response.data;
  //     //document.body.appendChild(a);
  //     a.click();
  //   } catch (error) {
  //     console.error(error);
  //     alert("failed to download CSV");
  //   }
  // };

  const onDownload = async () => {
    try {
      const reqBody = campaignFilter ? { campaignId: campaignFilter } : {};
      const response = await axios.post(
        `${process.env.REACT_APP_DATAPROVIDER_URL}/csv`,
        reqBody,
        {
          responseType: 'blob', // Important: Set the response type to 'blob'
        }
      );
      console.log('csv', response.data);
  
      // Create a Blob from the response data with the right type
      const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
  
      // Create an Object URL for the blob
      const url = URL.createObjectURL(blob);
  
      // Create an anchor element and trigger download
      const a = document.createElement("a");
      a.textContent = "download";
      a.download = "receipts.xlsx";
      a.href = url;
      document.body.appendChild(a); // Append to body to ensure it works in Firefox
      a.click();
  
      // Clean up by revoking the Object URL
      URL.revokeObjectURL(url);
      document.body.removeChild(a); // Remove the anchor element after clicking it
  
    } catch (error) {
      console.error(error);
      alert("Failed to download CSV");
    }
  };

  return (
    <>
      <TitleBox>
        <Typography variant="h1">Receipts</Typography>
        <Box
          sx={{
            display: "flex",
            pl: "20px",
          }}
        >
          <FormControl
            sx={{
              minWidth: 130,
              ".MuiInputLabel-root": {
                transform: "translate(14px, 10px) scale(1);",
              },
              ".MuiInputLabel-shrink": {
                transform: "translate(14px, -9px) scale(0.75);",
              },
            }}
          >
            <InputLabel id="campaign-label">All Campaigns</InputLabel>
            <Select
              id="campaign-select"
              label="Campaign"
              value={campaignFilter}
              onChange={onCampaignChange}
              sx={{ backgroundColor: "white", height: "35px" }}
            >
              <MenuItem key="campaign-all" value="">
                All Campaigns
              </MenuItem>
              {campaigns.map((option) => (
                <MenuItem key={option?.id} value={option?.id}>
                  {option?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ width: "100%", textAlign: "right" }}>
          <Button
            onClick={onDownload}
            sx={{
              width: "auto",
              height: "35px",
              backgroundColor: "white",
              border: "1px solid lightgray",
              fontSize: "11px",
              fontWeight: "500",
            }}
            variant="outlined"
            startIcon={<FileDownloadOutlinedIcon />}
          >
            Generate CSV
          </Button>
        </Box>
      </TitleBox>
      <Content>
        <Box sx={{ padding: "15px", display: "flex" }}>
          <ToggleButtonGroup
            value={statusFilter}
            aria-label="filter-by-status-options"
            exclusive
            onChange={handleStatusFilter}
            sx={{
              display: "inline-block",
              textAlign: "left",
              width: "100%",
            }}
          >
            {statusRadioOptions.map((option) => (
              <ToggleButton
                key={option.label}
                value={option.value}
                sx={{
                  fontSize: "12px",
                  padding: "10px 15px",
                  height: "100%",
                  "&.Mui-selected, &.Mui-selected:hover": {
                    backgroundColor: "#e9eef3",
                  },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "600 !important",
                    fontSize: "14px !important",
                    color: "black",
                    textTransform: "none",
                  }}
                >
                  {option.label}
                </Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          {/* <Box
            sx={{ display: "inline-block", width: "25%", textAlign: "right" }}
          >
            <Button
              onClick={onDownload}
              sx={{ width: "auto", height: "100%" }}
              variant="contained"
              color="primary"
            >
              Generate CSV
            </Button>
          </Box> */}
          {/* <Box sx={{display: 'inline-block', width: '25%', textAlign: 'right'}}>
              <Button href="https://file-examples.com/storage/febf69dcf3656dfd992b0fa/2017/10/file_example_JPG_100kB.jpg" download sx={{width: 'auto', height: '100%'}} variant="contained" color="primary" >
                Generate CSV
              </Button>
            </Box> */}

          {/* <Box
            sx={{ display: "inline-block", width: "25%", textAlign: "right" }}
          >
            <FormControl
              sx={{ m: 1, minWidth: 120, margin: "0px", textAlign: "initial" }}
            >
              <InputLabel id="campaign-label">Campaign</InputLabel>
              <Select
                id="campaign-select"
                label="Campaign"
                value={campaignFilter}
                onChange={onCampaignChange}
              >
                <MenuItem key="campaign-all" value="">
                  All
                </MenuItem>
                {campaigns.map((option) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    {option?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box> */}

          <Box sx={{ width: "100%", textAlign: "right" }}>
            <TextField
              label="Search"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton
                    type="button"
                    aria-label="search"
                    onClick={handleSearchFilter}
                  >
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              value={searchBarValue}
              onChange={onSearchValueChange}
              onKeyDown={(event: React.KeyboardEvent) => {
                if (event.key === "Enter") {
                  handleSearchFilter();
                }
              }}
            />
          </Box>
        </Box>
        <StyledDataGrid
          {...dataGridProps}
          columns={columns}
          rowHeight={75}
          autoHeight
          disableColumnFilter
          disableColumnSelector
          filterModel={undefined}
          /* getRowClassName={
              (params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            } */
          pageSizeOptions={pageSizeOptions}
          slots={{
            footer: CustomFooter,
          }}
        />
      </Content>
    </>
  );
};

function CustomFooter(props: any) {
  /* return <GridPagination ActionsComponent={MyPagination} {...props} />; */
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const [pageCount, setPageCount] = useState(1);
  const [pageInput, setPageInput] = useState("1");
  const [rowStartIndex, setRowStartIndex] = useState(1);
  const [rowEndIndex, setRowEndIndex] = useState(1);
  useEffect(() => {
    setPageCount(Math.ceil(rowCount / pageSize));
  }, [rowCount, pageSize]);
  useEffect(() => {
    setPageInput((page + 1).toString());
    setRowStartIndex(pageSize * page + 1);
    setRowEndIndex(Math.min(pageSize * (page + 1), rowCount));
  }, [page, pageSize, rowCount]);

  return (
    <>
      {/* <GridPagination /> */}
      <Box sx={{ display: "flex" }}>
        <FooterItem sx={{ justifyContent: "left" }}>
          <FooterText>
            Displaying {rowStartIndex} - {rowEndIndex} of {rowCount}
          </FooterText>
        </FooterItem>
        <FooterItem sx={{ justifyContent: "center" }}>
          <Button
            onClick={() => {
              apiRef.current.setPage(0);
            }}
            disabled={page == 0}
            startIcon={<FirstPageIcon />}
          />
          <Button
            onClick={() => {
              page + 1 <= 1 ? apiRef.current.setPage(pageCount):apiRef.current.setPage(page - 1);
            }}
            // disabled={page + 1 <= 1}
            startIcon={<ArrowBackOutlinedIcon />}
          />
          <TextField
            variant="outlined"
            value={pageInput}
            sx={{
              maxWidth: "70px",
              width: "auto",
            }}
            inputProps={{ style: { textAlign: "center" } }}
            onChange={(event) => {
              const enteredValue = event.target.value;
              setPageInput(enteredValue);
              if (
                Number(enteredValue) <= pageCount &&
                Number(enteredValue) >= 1
              ) {
                apiRef.current.setPage(Number(enteredValue) - 1);
              }
            }}
          />
          <FooterText sx={{ marginLeft: "10px" }}>
            of {pageCount} pages
          </FooterText>
          <Button
            onClick={() => {
              page + 1 >= pageCount ? apiRef.current.setPage(0) : apiRef.current.setPage(page + 1);
            }}
            // disabled={page + 1 >= pageCount}
            startIcon={<ArrowForwardOutlinedIcon />}
          />
          <Button
            onClick={() => {
              apiRef.current.setPage(pageCount);
            }}
            disabled={page+1 == pageCount}
            startIcon={<LastPageIcon />}
          />
        </FooterItem>

        <FooterItem sx={{ justifyContent: "right" }}>
          <FooterText sx={{ marginRight: "10px" }}>Show </FooterText>
          <TextField
            id="select-page-size"
            select
            value={pageSize}
            onChange={(e) => apiRef.current.setPageSize(Number(e.target.value))}
          >
            {pageSizeOptions.map((option) => (
              <MenuItem key={"pageSize-" + option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <FooterText sx={{ marginLeft: "10px" }}>entries</FooterText>
        </FooterItem>
      </Box>
    </>
  );
}
