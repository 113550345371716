import React, { useState, ReactElement, useEffect, useRef } from "react";
import { Edit, useAutocomplete,Show } from "@refinedev/mui";
import {
  TextField,
  Autocomplete,
  Typography,
  InputLabel,
  Table,
  TableBody,
  TableRow,
  TableCell,
  InputAdornment,
  BoxProps,
  Stack,
  Switch,
  Select,
  MenuItem,
  IconButton,
  Box,
  CircularProgress
} from "@mui/material";
// import { Show } from "@refinedev/mui";
// import { useRouter } from 'next/router';
// import IconButton from '@mui/material/IconButton';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm } from "@refinedev/react-hook-form";
import {
  IResourceComponentsProps,
  useWarnAboutChange,
  useGo,
  useList,
  HttpError,
  useOne,
  useShow,
} from "@refinedev/core";
import { Controller, FieldValues } from "react-hook-form";
import styled from "@emotion/styled";
// import Box from "lib/ui/mui/Box";
import Button from "lib/ui/mui/Button";
import Card from "lib/ui/mui/Card";
import StatusChip from "lib/ui/core/StatusChip";
import { EditLayout } from "components/editLayout";
import { CustomTable } from "components/table";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import ZoomOutOutlinedIcon from "@mui/icons-material/ZoomOutOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import { statusColorMapping } from "constant/color-mapping";
import { submitReceiptUpload } from 'api/receiptUpload';
import { submitReScan } from 'api/reScan';
const css = `
  .react-transform-wrapper, .react-transform-component {
    width: 100% !important;
  }
`;

const TitleBox = styled(Box)(({ theme }) => ({
  paddingBottom: "30px",
}));

const Content = styled(Card)(({ theme }) => ({}));

const ReceiptPhotoContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  border: "1px solid lightgray",
}));

interface ReceiptPhotoBoxProps extends BoxProps {
  url?: string;
}
const ReceiptPhotoBox = styled(Box)<ReceiptPhotoBoxProps>(({ url, theme }) => ({
  //padding: "16px",
  background: "#fff",
  //width: "auto",
  //fontSize: "50px",
  //fontWeight: "bold",
  //display: "flex",
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundImage: `url(${url})`,
  cursor: "pointer",
  //flex: 1,
}));

const ReceiptDetailsContainer = styled(Box)(({ theme }) => ({
  //component: "form",
  //autoComplete: "off",
  //display: "flex",
  //flexDirection: "column",
  //alignItems: "right",
  padding: "0 60px",
  width: "800px",
}));

const ReceiptErrorBox = styled(Box)(({ theme }) => ({
  //component: "form",
  //autoComplete: "off",
  display: "flex",
  //flexDirection: "column",
  alignItems: "center",
  justifyContent:"center",
  padding: "60px",
  height: "100%",
  width: "100%",
}));

const ShowTableCell = styled(TableCell)(({ theme }) => ({
  padding: "0px 0px 10px 0px",
  border: "0",
}));

export const ShowLabel = styled(InputLabel)(({ theme, title }) => ({
  color: "black",
  fontSize: "11px !important",
}));

const ShowInput = styled(TextField)(({ theme }) => ({
  paddingLeft: "12px",
  width: "100%",
  "& .MuiInputBase-root": {
    fontSize: "12px",
    "&:before, &:after": {
      borderBottom: "none !important",
    },
  },
}));

const EditItem = styled(Box)(({ theme }) => ({
  display: "grid",
  width: "350px",
  paddingBottom: "15px",
}));

const EditLabel = styled(InputLabel)(({ theme }) => ({
  color: "black",
  fontSize: "14px !important",
  paddingBottom: "5px",
}));
const ImageBox = styled(Box)(({ theme }) => ({
  display: "inline-block",
  width: "100px",
  height: "100px",
  margin: '5px',
  "& .galleryImg": {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
}));

const GalleryWrapper = styled(Box)(({ theme }) => ({
    display:"flex",
    width:"100%",
    maxWidth:"500px",
    flexWrap:"wrap"
  }));

export const EditTextField = styled(TextField)(({ theme }) => ({
  margin: "0",
  "& .MuiInputBase-input": {
    fontSize: "16px",
    padding: "10px 14px",
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
}));

const EditButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#f9f5ff",
  margin: "10px 20px 0 0",
  color: "#6941c6",
  maxWidth: "140px",
  "& .MuiTypography-root": {
    fontWeight: "500",
    fontSize: "13px",
  },
}));

const ReScanButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#f9f5ff",
  margin: "10px 20px 0 0",
  color: "#6941c6",
  maxWidth: "140px",
  "& .MuiTypography-root": {
    fontWeight: "500",
    fontSize: "13px",
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  marginTop: "10px",
  padding: "8px 12px",
  maxWidth: "120px",
  "& .MuiTypography-root": {
    fontWeight: "500",
  },
}));

export const SaveButton = styled(Button)(({ theme }) => ({
  marginTop: "10px",
  padding: "8px 12px",
  maxWidth: "160px",
  color: "white",
  backgroundColor: "#7f56d9",
  "& .MuiTypography-root": {
    fontWeight: "500",
  },
  "&:hover": {
    backgroundColor: "#9470e4",
    boxShadow: "none",
  },
}));

export interface ExtendedIResourceComponentsProps
  extends IResourceComponentsProps {
  rows?: any;
  ids?: any;
}
export const BlogPostEdit: React.FC<ExtendedIResourceComponentsProps> = ({
  rows,
  ids,
}) => {
  
  const { setWarnWhen } = useWarnAboutChange();
  const go = useGo();
  
  const {
    saveButtonProps,
    refineCore: { queryResult, onFinish },
    register,
    control,
    formState: { errors, isLoading },
    handleSubmit,
    reset,
  } = useForm({
    refineCoreProps: {
      redirect: false,
    },
  });
  /* const { autocompleteProps: categoryAutocompleteProps } = useAutocomplete({
        resource: "categories",
        defaultValue: blogPostsData?.category?.id,
    }); */
  const receiptsData = queryResult?.data?.data;
  // const router = useRouter();
  // States
  const [receiptsError, setReceiptsError] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [nextId, setNextId] = useState(undefined);
  const [prevId, setPrevId] = useState(undefined);
  const [lineItems, setLineItems] = useState([]);
  const [currentImage, setCurrentImage] = useState(receiptsData?.metaData && receiptsData?.metaData.images[0]?receiptsData?.metaData.images[0].url?receiptsData.metaData.images[0].url:receiptsData.metaData.images[0]:receiptsData?.image);
  const [currentImageId, setCurrentImageId] = useState(receiptsData?.metaData && receiptsData?.metaData.images[0]?
    receiptsData?.metaData.images[0].id?
    receiptsData.metaData.images[0].id:
    null:null);
  const [ocrResultPaymentMethod,setOcrResultPaymentMethod] = useState('');
  const [currentCurrency,setCurrentCurrency] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [currentImageData, setCurrentImageData] = useState(false);
  const [currentCategory, setCurrentCategory] = useState('');
  const [rotation, setRotation] = useState(0); // State for rotation (in degrees)
  const [reScanBtn, setReScanBtn] = useState(true);
  const canvasRef = useRef(null);
  console.log('receiptsData:',reScanBtn,
    receiptsData,receiptsData?.metaData && receiptsData?.metaData.images[0]?
    receiptsData?.metaData.images[0].id?
    receiptsData.metaData.images[0].id:
    null:null,currentImageId,receiptsError,Array.isArray(receiptsData?.ocrResult?.receipts));
  // console.log('Mastercard ending with 2201',register("paymentMethod", {}));
  interface IPaymentMethods {
    id: number;
    name: string;
    status: number;
  }
  const { data:paymentMethods, isLoading:paymentListLoging, isError } = useList<IPaymentMethods, HttpError>({
    resource: "paymentMethods",
  });
  const paymentList = paymentMethods?.data?.map(item=>item.name) ?? [];

  interface ICurrencies {
    id: number;
    name: string;
  }
  const { data:currenciesData, isLoading:currenciesLoging} = useList<ICurrencies, HttpError>({
    resource: "currencies",
  });
  
  const currencies = currenciesData?.data?currenciesData.data.length>0?currenciesData.data.map(item=>item.name):['HKD']:['HKD'];
  // const currencies = ['HKD','CNY'];
  console.log('paymentList',paymentList,currencies);

  interface ICategory {
    id: number;
    name: string;
  }
  const { data:categoryData, isLoading:categoryLoging} = useList<ICategory, HttpError>({
    resource: "productCategories",
  });
  
  const categories = categoryData?.data?categoryData.data.length>0?categoryData.data.map(item=>item.name):[{'id':'1','name':'a'},{'id':'2','name':'b'}]:['a','b'];
  // const categories = ['HKD','CNY'];
  useEffect(()=>{
    console.log('useEffectIsLoading',isLoading?'t':'f');
  },[isLoading])
  useEffect(() => {
    setCurrentImage(
      receiptsData?receiptsData?.metaData && receiptsData?.metaData.images[0]?
      receiptsData?.metaData.images[0].url?receiptsData.metaData.images[0].url:receiptsData.metaData.images[0]:receiptsData?.image:
      'https://placehold.co/500x500?text=loading'
    );
    
    setRotation(0);
  },[nextId, prevId]);


  useEffect(() => {
    // setReScanBtn(!receiptsData?.status.includes('In-Review') || rotation === 0);
    setCurrentImage(
      receiptsData?receiptsData?.metaData && receiptsData?.metaData.images[0]?
      receiptsData?.metaData.images[0].url?receiptsData.metaData.images[0].url:receiptsData.metaData.images[0]:receiptsData?.image:
      'https://placehold.co/500x500?text=loading'
    );
    const imgLoading = new Image()
    imgLoading.src = currentImage?currentImage:'https://placehold.co/500x500?text=loading';
    imgLoading.onload = () =>{
      setCurrentImageData(!currentImage?false:true);
      console.log("receiptsDataImgLoading",currentImage,currentImageData,imgLoading.src);
    };
    setRotation(0);
    console.log('receiptsDataCurrentImage',receiptsData,reScanBtn);
  },[receiptsData]);

  const handleApprove = (data: FieldValues) => {
    onFinish({ status: "Approved" }).then((res) => {
      setIsEditMode(false);
      if (nextId) {
        go({ to: `/receipt/edit/${nextId}` });
      }
    });
  };

  const handleRotate = (degrees:any) => {
    // resetTransform();
    setReScanBtn(true);
    setRotation((prevRotation) => (prevRotation + degrees) % 360);
  };

  const handleReject = (data: FieldValues) => {
    onFinish({ status: "Rejected" }).then((res) => {
      setIsEditMode(false);
      if (nextId) {
        go({ to: `/receipt/edit/${nextId}` });
      }
    });
  };

  const handleSave = (data: FieldValues) => {
    delete data.campaignId;
    delete data.campaign;
    console.log('handleSave:',data)
    // use datagrid value as ocrResult line items
    data.ocrResult.items = lineItems
    onFinish(data).then((res) => {
      setIsEditMode(false);
    });
  };

const handleSubmitReScan = async ()=>{
          // handleSaveImage(currentImage);
          // onFinish({ status: "Approved" }).then((res) => {
          //   setIsEditMode(false);
          //   if (receiptsData?.id) {
          //     go({ to: `/receipt/edit/${receiptsData?.id}` });
          //   }
          // });
          
          console.log('handleSubmitReScan',receiptsData);
          setCurrentImageData(false);
          setRotation(0);
          try { 
                
                // let formData = new FormData();
                // formData.append("receiptId", receiptsData?.id as any);
                // formData.append("imageId",currentImageId);
                // formData.append("image", 'aaaa');
                // formData.append("campaignId", +campaign_id as any);
                // formData.append('name', items.name);
                // formData.append('email', items.email);
                // formData.append('phoneNo', items.phoneNo);
                // formData.append('phoneNoCountry', items.phoneNoCountry);
                // (items.receiptImages as string[]).forEach((img: any) => {
                    // formData.append('images', img.file);
                // });
                let formData = {receiptId: receiptsData?.id};
                console.log('formData:',formData);
                // setLoadingResponse(true);
                const res = await submitReScan(formData);
                if (res) {
                    // setLoadingResponse(false);
                    // setSubmitSuccess(true);
                    console.log("submitReScanRES:",res);
                    go({ to: `/receipt/edit/${receiptsData?.id}` });
                    window.location.reload();
                    // interface IPictureSource {
                    //   data: any;
                    // }
                    // const { data:useData,isLoading:useLoading,isError:useError}= useShow<IPictureSource,>({ 
                    //   resource:'useData'
                    // });
                    // let usePicData=useData?.data;
                    // console.log('usePicData:',usePicData,useLoading);
                    let data = receiptsData?receiptsData:{campaignId:'',campaign:''};
                    delete data.campaignId;
                    delete data.campaign;
                    console.log('isLoading:',isLoading);
                    onFinish({status: "In-Review" }).then((res) => {
                      // setIsEditMode(false);
                      // let receiptsData = res?.data;
                      // let rotationTemp = rotation;
                      // setCurrentImage(receiptsData?.metaData && receiptsData?.metaData.images[0]?receiptsData?.metaData.images[0].url?receiptsData.metaData.images[0].url:receiptsData.metaData.images[0]:receiptsData?.image);
                      // setRotation(0);
                      console.log('submitReScanFinish',res);
                      // setReScanBtn(!receiptsData?.status.includes('In-Review')|| rotationTemp===0);
                    });
                    // router.replace({ pathname: '/ReceiptUploadForm/UploadResult', query: { isSuccessful: true } }, '/ReceiptUploadForm/UploadResult');
                }
            } catch (error: any) {
                // setLoadingResponse(false);
                // setSubmitSuccess(false);
                console.log(error)
                if (error.request.status == 413) {
                  console.log("submitReScanErr", { type: "any.invalid", message: "File is too large. Please try upload again." });
                    // setError("receiptImages", { type: "any.invalid", message: "File is too large. Please try upload again." });
                } else {
                  console.log("submitReScanErr", { type: "any.invalid", message: error + " Please try upload again." });
                  // go({ to: `/receipts` });
                  // setError("receiptImages", { type: "any.invalid", message: error + " Please try upload again." });
                }
            }
}

  const handleSaveImage = async (imageSrc:any)=>{
    // return new Promise((resolve)=>{
      if(!rotation|| !(rotation%360)){
        // resolve(imageSrc.currentImage);
        return;
      }

      setCurrentImageData(false);

      // const img = new Image();
      // // img.src = imageSrc.currentImage;
      // img.src = 'http://localhost:3000/temp.png'
      // img.setAttribute("crossOrigin","anonymous");
      // img.onload = async ()=>{
      //   const width = img.width, 
      //         height = img.height;
      //   const x = 0,
      //         y = 0;
      //   const canvas = document.createElement("canvas");
      //   canvas.width = rotation % 180 ? height : width;
      //   canvas.height = rotation % 180 ? width : height;
      //   const ctx = canvas.getContext("2d");
      //   ctx?.save();
      //   ctx?.translate(x + canvas.width / 2, y + canvas.height / 2);
      //   ctx?.rotate(rotation*(Math.PI / 180));
      //   if(rotation % 180){
      //     ctx?.translate(-x - canvas.height / 2, -y - canvas.width /2);
      //     ctx?.drawImage(img, x, y, canvas.height, canvas.width);
      //   }else{
      //     ctx?.translate(-x - canvas.width / 2, -y - canvas.height /2);
      //     ctx?.drawImage(img, x, y, canvas.width, canvas.height);
      //   }
      //   ctx?.restore();
      //   const src = canvas.toDataURL("image/png");
        console.log('handleSaveImage:',imageSrc);
        try { 
                
          // let formData = new FormData();
          // formData.append("receiptId", receiptsData?.id as any);
          // formData.append("imageId",currentImageId);
          // formData.append("image", src);
          // formData.append("campaignId", +campaign_id as any);
          // formData.append('name', items.name);
          // formData.append('email', items.email);
          // formData.append('phoneNo', items.phoneNo);
          // formData.append('phoneNoCountry', items.phoneNoCountry);
          // (items.receiptImages as string[]).forEach((img: any) => {
              // formData.append('images', img.file);
          // });
          let formData ={receiptId:receiptsData?.id,imageId:currentImageId?currentImageId:receiptsData?.metaData.images[0].id,rotation:rotation}
          // setLoadingResponse(true);
          const res = await submitReceiptUpload(formData);
          if (res) {
              // setLoadingResponse(false);
              // setSubmitSuccess(true);
              
              console.log("handleSaveImageRES:",res);
              go({ to: `/receipt/edit/${receiptsData?.id}`});
              console.log('handleSaveImageRES:isLoading',isLoading);
              // window.setTimeout( ()=>{
                // refetch();
                onFinish({ status: "In-Review" }).then((res) => {
                  // setIsEditMode(false);
                  let receiptsData = res?.data;
                  let rotationTemp = rotation;
                  setCurrentImage(receiptsData?.metaData && receiptsData?.metaData.images[0]?receiptsData?.metaData.images[0].url?receiptsData.metaData.images[0].url:receiptsData.metaData.images[0]:receiptsData?.image);
                  setRotation(0);
                  console.log('onFinish',res,receiptsData,rotationTemp,rotation);
                  setReScanBtn(!receiptsData?.status.includes('In-Review')|| rotationTemp===0);
                });
                // setReScanBtn(false);
                console.log("setTimeout:",queryResult);
              // },1000);
              
              // setRotation(0);
              // window.location.reload();
              // router.replace({ pathname: '/ReceiptUploadForm/UploadResult', query: { isSuccessful: true } }, '/ReceiptUploadForm/UploadResult');
          }
        } catch (error: any) {
            // setLoadingResponse(false);
            // setSubmitSuccess(false);
            console.log(error)
            if (error.request.status == 413) {
              console.log("receiptImagesErr", { type: "any.invalid", message: "File is too large. Please try upload again." });
                // setError("receiptImages", { type: "any.invalid", message: "File is too large. Please try upload again." });
            } else {
              console.log("receiptImagesErr", { type: "any.invalid", message: error + " Please try upload again." });
                // setError("receiptImages", { type: "any.invalid", message: error + " Please try upload again." });
            }
        }
      // }
    // })
  }

  const handleSaveImageBak = async (imageSrc:any) => {
    console.log('handleSaveImage:',imageSrc.currentImage,canvasRef);
    if (!imageSrc.currentImage ) return;
    // console.log('handleSaveImage:',currentImage,canvasRef);
    const canvas = canvasRef.current;
    function isCanvas(canvas: HTMLCanvasElement | null): canvas is HTMLCanvasElement {
      return canvas !== null && typeof canvas.getContext === 'function'; // Check for getContext method
    }
    if (isCanvas(canvas)) {
      const ctx = (canvas as HTMLCanvasElement).getContext('2d');
      if (ctx === null) {
        console.error('Failed to get 2D context for canvas');
        return; // Exit if context is null
      }
      const img = new Image();
      img.src = 'http://localhost:3000/temp.png';
      // img.crossOrigin = 'Anonymous';
      // img.setAttribute('crossOrigin','');
      img.onload = () => {
        console.log(canvas,img.width,img.height);
        // Once the image is loaded, we will get the width & height of the image
        let loadedImageWidth = img.width;
        let loadedImageHeight = img.height;

        // get the scale
        // it is the min of the 2 ratios
        let scale_factor = Math.min((canvas as HTMLCanvasElement).width / img.width, (canvas as HTMLCanvasElement).height / img.height);
        
        // Lets get the new width and height based on the scale factor
        let newWidth = img.width * scale_factor;
        let newHeight = img.height * scale_factor;
            
        // get the top left position of the image
        // in order to center the image within the canvas
        let x = ((canvas as HTMLCanvasElement).width / 2) - (newWidth / 2);
        let y = ((canvas as HTMLCanvasElement).height / 2) - (newHeight / 2);
        // ctx.rotate((rotation * Math.PI) / 180);
        // When drawing the image, we have to scale down the image
        // width and height in order to fit within the canvas
        ctx.drawImage(img, x, y, newWidth, newHeight);
        // ctx.clearRect(0, 0, img.width, img.height);
        // ctx.translate((canvas as HTMLCanvasElement).width / 2, (canvas as HTMLCanvasElement).height / 2); // Center image
        // ctx.rotate((rotation * Math.PI) / 180); // Apply rotation
        // ctx.drawImage(img, -img.width / 2, -img.height / 2); // Draw centered
        
        console.log('dataURL',img,rotation,ctx,canvas,(canvas as HTMLCanvasElement).toDataURL('image/png'));
        
        const dataURL = (canvas as HTMLCanvasElement).toDataURL('image/png');

        setRotation(0);

        

        // Send dataURL to your server for saving
        // (Replace '/save-image' with your server endpoint)
        fetch('v1/images/rotate', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ imageData: dataURL }),
        })
        .then(response => {
          if (response.ok) {
            console.log('Image saved successfully!');
          } else {
            console.error('Error saving image');
          }
        })
        .catch(error => {
          console.error('Error saving image:', error);
        });
      };
    } else {
      console.error('Canvas is not initialized');
    }
  };

  const handleImageClick = (imageUrl:string,imageId:any)=>{
    setCurrentImage(imageUrl);
    setCurrentImageId(imageId);
    setRotation(0);
  }
  type CustomTable = {
    categories:string[]
  };

  const showTableRows = [
    { label: "Merchant Name", fieldName: "merchantName" },
    { label: "Receipt No.", fieldName: "No" },
    { label: "Line Items", fieldName: "ocrResult" },
    { label: "Amount", fieldName: "amount" },
    { label: "Payment Date", fieldName: "paymentDate" },
    { label: "Submission Date", fieldName: "createdAt" },
    { label: "Last Updated", fieldName: "updatedAt" },
    { label: "User Name", fieldName: "name" },
    { label: "User Email", fieldName: "email" },
    { label: "Status", fieldName: "status" },
    { label: "Campaign", fieldName: "campaign.name" },
    { label: "Remark", fieldName: "remarks", defaultValue: "-" },
    { label: "Discount", fieldName: "discount" },
    { label: "Payment Method", fieldName: "paymentMethod" },
    { label: "Shopping Type", fieldName: "shoppingType" },
    { label: "Currency", fieldName: "currency" },
    { label: "Source ", fieldName: "source" },
  ];

  const Controls = (currentImage:any) => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    // console.log('imageTemplate:',currentImage);
    return (
      <>
        <Box sx={{ width: "40px", marginLeft: "auto", padding: "20px 5px" ,position: 'absolute',right: "-40px" }}>
          <ZoomInOutlinedIcon
            sx={{
              width: "100%",
              cursor: "pointer",
              fontSize: "30px",
            }}
            onClick={() => zoomIn()}
          />
          <ZoomOutOutlinedIcon
            sx={{
              width: "100%",
              cursor: "pointer",
              fontSize: "30px",
            }}
            onClick={() => zoomOut()}
          />
          <RotateRightOutlinedIcon 
            sx={{
              width: "100%",
              cursor: "pointer",
              fontSize: "30px",
            }}
            onClick={() => {resetTransform();handleRotate(90)}} 
          />
          <RotateLeftOutlinedIcon 
            sx={{
              width: "100%",
              cursor: "pointer",
              fontSize: "30px",
            }}
            onClick={() => {resetTransform();handleRotate(-90)}} 
          />
          <IconButton color="primary" 
              sx={{
                padding: "5px"
              }}
              disabled={!currentImage || rotation===0}
              onClick={() => {
                handleSaveImage(currentImage);
                // handleSubmitImage();
                console.log(currentImage,rotation);
                resetTransform(); 
                
              }} 
              >
            <SaveOutlinedIcon />  
          </IconButton>
          
          {/* <button style={{ width: "100%" }} onClick={() => resetTransform()}>
            Reset
          </button> */}
        </Box>
      </>
    );
  };

  return (
    <>
      <style>{css}</style>
      <TitleBox>
        <Typography variant='h1'>Receipt Details</Typography>
      </TitleBox>
      {!currentImageData&&
        (<Content
        sx={{          
          position:"fixed",
          inset: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100dvh",
          opacity: 0.8,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        ><CircularProgress /> </Content>
      )
      }
      <Content>
        <EditLayout
          saveButtonProps={{
            ...saveButtonProps,
            onClick: handleSubmit(handleSave),
          }}
          approveButtonProps={{
            ...saveButtonProps,
            onClick: handleSubmit(handleApprove),
          }}
          rejectButtonProps={{
            ...saveButtonProps,
            onClick: handleSubmit(handleReject),
          }}
          isLoading={isLoading}
          rows={rows}
          ids={ids}
          status={receiptsData?.status}
          setIsEditMode={setIsEditMode}
          receiptsError={receiptsError}
          setReceiptsError={setReceiptsError}
          // currentImage={
          //   currentImage?currentImage:
          //   receiptsData?.metaData && receiptsData?.metaData.images[0]?
          //   receiptsData?.metaData.images[0].url?
          //   receiptsData?.metaData.images[0].url:
          //   receiptsData?.metaData.images[0]:
          //   receiptsData?.image}
          nextId={nextId}
          setNextId={setNextId}
          prevId={prevId}
          setPrevId={setPrevId}
        >
          
          <Stack direction='row'>
            <ReceiptPhotoContainer sx={{ position: 'relative',flexDirection: 'column' }}>
            {/* <canvas ref={canvasRef}></canvas> */}
              <TransformWrapper initialScale={1}>
                <TransformComponent>
                  {/* <img alt="zoom pic" */}
                  <ReceiptPhotoBox
                    url={
                      currentImage?
                      currentImage:
                      receiptsData?
                      receiptsData?.metaData && receiptsData?.metaData.images[0]?receiptsData?.metaData.images[0].url?
                      receiptsData?.metaData.images[0].url:
                      receiptsData?.metaData.images[0]:
                      receiptsData?.image:'https://placehold.co/500x500?text=loading' 
                    }
                    sx={{ width: "100%", height: "400px" }}
                    style={{ width: '100%', transform: `rotate(${rotation}deg)`,minWidth: '400px', maxWidth: '400px' }}
                    onLoad={() => {
                      console.log('picture onload');
                    }}
                  />
                </TransformComponent>
                <Controls key={'ControlsComponent'}
                  currentImage={
                      currentImage?
                      currentImage:
                      receiptsData?
                      receiptsData?.metaData && receiptsData?.metaData.images[0]?receiptsData?.metaData.images[0].url?
                      receiptsData?.metaData.images[0].url:
                      receiptsData?.metaData.images[0]:
                      receiptsData?.image:'https://placehold.co/500x500?text=loading' } />
              </TransformWrapper>
              <GalleryWrapper>
              { currentImageData && receiptsData && receiptsData.metaData ? receiptsData.metaData.images.map(
                (image:any,index:number)=>{
                  console.log('mapGallery:',image,currentImageData);
                  return(
                  <ImageBox sx={{border:(index===0 && !currentImage) || (image.url && currentImage === image.url) || currentImage === image ? '2px solid blue': '1px solid gray'}}>
                    <img key={image.id?image.id:index} src={image.url?image.url:'https://placehold.co/500x500?text=loading' } className='galleryImg' alt=''
                      onClick={()=>handleImageClick(image.url?image.url:image,image.id?image.id:null)}
                    />
                  </ImageBox>
                )}
              ):(currentImageData &&
              <ImageBox sx={{border:(!currentImage) ||currentImage === receiptsData?.image ? '2px solid blue': '1px solid gray'}}>
                <img src={receiptsData?.image} className='galleryImg' alt=''
                  onClick={()=>handleImageClick(receiptsData?.image,null)}
                />
              </ImageBox>
            )
              }
            </GalleryWrapper>
            </ReceiptPhotoContainer>

            


            <ReceiptDetailsContainer>
              {isEditMode ? (
                <>
                  <EditItem>
                    <EditLabel>Merchant Name</EditLabel>
                    <EditTextField
                      {...register("merchantName", {})}
                      error={!!(errors as any)?.merchantName}
                      helperText={(errors as any)?.merchantName?.message}
                      margin='normal'
                      type='text'
                      name='merchantName'
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Receipt No.</EditLabel>
                    <EditTextField
                      {...register("No", {
                        /* required: "This field is required", */
                      })}
                      type='text'
                      error={!!(errors as any)?.No}
                      helperText={(errors as any)?.No?.message}
                      margin='normal'
                      InputLabelProps={{ shrink: true }}
                      name='No'
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Line Items</EditLabel>
                    <Controller
                      control={control}
                      name='ocrResult'
                      render={({ field }) => {
                        if(field.value && field.value.payment_method && field.value.payment_method !=null ){
                          setOcrResultPaymentMethod(field.value.payment_method);
                        }
                        if (
                          !field.value ||
                          field.value.items === null ||
                          !field.value.items
                        ) {
                          // console.log('field', field.value);
                          return <p>-</p>;
                        }
                        return (
                          <CustomTable
                            rows={field.value.items}
                            categories={categories}
                            currentCategory={currentCategory}
                            setCurrentCategory={setCurrentCategory}
                            isEditing={isEditMode}
                            onChange={(data)=> {
                              // console.log('after changing',data)
                              setLineItems(data)
                            }}
                          />
                        );
                      }}
                    />
                  </EditItem>
                  {/* <Controller
                                control={control}
                                name="category"
                                // eslint-disable-next-line
                                defaultValue={null as any}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...categoryAutocompleteProps}
                                        {...field}
                                        onChange={(_, value) => {
                                            field.onChange(value);
                                        }}
                                        getOptionLabel={(item) => {
                                            return (
                                                categoryAutocompleteProps?.options?.find(
                                                    (p) =>
                                                        p?.id?.toString() ===
                                                        item?.id?.toString(),
                                                )?.title ?? ""
                                            );
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            value === undefined ||
                                            option?.id?.toString() === value?.id?.toString()
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Amount"
                                                margin="normal"
                                                variant="outlined"
                                                error={!!(errors as any)?.category?.id}
                                                helperText={
                                                    (errors as any)?.category?.id?.message
                                                }
                                                required
                                            />
                                        )}
                                    />
                                )}
                            />  */}
                  {/* <EditItem>
                                <EditLabel>Status</EditLabel>
                                <EditTextField
                                    {...register("status", {
                                        required: "This field is required", 
                                    })}
                                    error={!!(errors as any)?.status}
                                    helperText={(errors as any)?.status?.message}
                                    margin="normal"
                                    type="text"
                                    name="status"                          
                                />
                            </EditItem> */}
                  <EditItem>
                    <EditLabel>Amount</EditLabel>
                    <EditTextField
                      {...register("amount", {
                        /* required: "This field is required", */
                      })}
                      error={!!(errors as any)?.amount}
                      helperText={(errors as any)?.amount?.message}
                      margin='normal'
                      type='text'
                      name='amount'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position='start'
                            sx={{
                              "& .MuiTypography-root": { fontSize: "16px" },
                            }}
                          >
                            HK${" "}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Payment Date</EditLabel>
                    <Controller
                      control={control}
                      name='paymentDate'
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            ampm={false}
                            onChange={(date) => field.onChange(date)}
                            value={dayjs(field.value)}
                            sx={{
                              "& .MuiInputBase-root": {
                                fontSize: "16px",
                              },
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Remarks</EditLabel>
                    <EditTextField
                      {...register("remarks", {})}
                      error={!!(errors as any)?.remarks}
                      helperText={(errors as any)?.remarks?.message}
                      margin='normal'
                      name='remarks'
                      multiline
                      rows={3}
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Discount</EditLabel>
                    <EditTextField
                      {...register("discount", {})}
                      error={!!(errors as any)?.discount}
                      helperText={(errors as any)?.discount?.message}
                      margin="normal"
                      name="discount"
                      rows={3}
                      placeholder="Enter discount"
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Payment Method</EditLabel>
                    <Controller
                      name="paymentMethod"
                      // name='ocrResult'
                      control={control}
                      render={({ field }) => {
                        console.log('paymentMethodField',field);
                        return(
                        <Autocomplete
                          disablePortal
                          value = {paymentMethod || field.value}
                          options={paymentList}
                          onChange = {(event,data) => {
                            // console.log('Autocomplete:',paymentMethod,paymentList,event,data);
                            if(data!=null){
                              field.onChange(data);
                              setPaymentMethod(data);
                            }
                          }}
                          inputValue = {paymentMethod}
                            onInputChange={(event, newInputValue) => {
                              if(newInputValue!=null){
                                field.onChange(newInputValue);
                                setPaymentMethod(newInputValue);
                              }
                            }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      )}
                    }
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Shopping Type</EditLabel>
                    <Controller
                      name="shoppingType"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="shopping-type-select-label"
                          id="shopping-type-select"
                          placeholder="Select shopping type status"
                        >
                          <MenuItem value={"Online"}>Online</MenuItem>
                          <MenuItem value={"Offline"}>Offline</MenuItem>
                        </Select>
                      )}
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Currency</EditLabel>
                    <Controller
                      name="currency"
                      control={control}
                      render={
                        ({ field }) => {
                        console.log('CurrencyField',field);
                          return (
                            <Autocomplete
                            disablePortal
                            freeSolo
                            value = {currentCurrency || field.value }
                            inputValue = {currentCurrency}
                            onInputChange={(event, newInputValue) => {
                              if(newInputValue!=null){
                                field.onChange(newInputValue);
                                setCurrentCurrency(newInputValue);
                              }
                            }}
                            options={currencies}
                            onChange = {(event,data) => {
                              console.log('Autocomplete:',event,data);
                              if(data!=null){
                                field.onChange(data);
                                setCurrentCurrency(data);
                              }
                              return data
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                      )}
                    }
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Source</EditLabel>
                    <Controller
                      name="source" // match key in GET /receipt/:id
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="source-select-label"
                          id="source-select"
                          placeholder="Select Source"
                        >
                          <MenuItem value={"Authorized"}>Authorized</MenuItem>
                          <MenuItem value={"Official"}>Official</MenuItem>
                          <MenuItem value={"Parallel Import"}>
                            Parallel Import
                          </MenuItem>
                        </Select>
                      )}
                    />
                  </EditItem>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <CancelButton
                      variant='outlined'
                      onClick={async () => {
                        setIsEditMode(false);
                        reset({ ...queryResult?.data?.data });
                        setWarnWhen(false);
                      }}
                    >
                      <Typography>Cancel</Typography>
                    </CancelButton>
                    <SaveButton
                      variant='outlined'
                      {...saveButtonProps}
                      onClick={handleSubmit(handleSave)}
                    >
                      <Typography>Save</Typography>
                    </SaveButton>
                  </Box>
                </>
              ) : !receiptsError?
              (
                <>
                  <Table key={'showTable'}>
                    <TableBody>
                      {showTableRows.map((row,index) => 
                        row.label!=='Line Items'?
                        (
                        <TableRow key={index}>
                          <ShowTableCell sx={{ width: "0" }}>
                            <ShowLabel>{row.label}</ShowLabel>
                          </ShowTableCell>
                          <ShowTableCell sx={{ width: "100%" }}>
                            {(() => {
                              // console.log('showTable:',row);
                              if (
                                row.fieldName === "paymentDate" ||
                                row.fieldName === "updatedAt" ||
                                row.fieldName === "createdAt"
                              ) {
                                return (
                                  <Controller
                                    control={control}
                                    name={row.fieldName}
                                    render={({ field }) => (
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <ShowInput
                                          variant='standard'
                                          inputProps={{ disabled: true }}
                                          type='text'
                                          name={row.fieldName}
                                          value={dayjs(field.value).format(
                                            "YYYY-MM-DD HH:mm:ss"
                                          )}
                                        />
                                      </LocalizationProvider>
                                    )}
                                  />
                                );
                              } else if (row.fieldName === "status") {
                                return (
                                  <Controller
                                    control={control}
                                    name={row.fieldName}
                                    render={({ field }) => {
                                      const { textColor, backgroundColor } =
                                        statusColorMapping[field.value] ||
                                        statusColorMapping["Default"];
                                      return (
                                        <StatusChip
                                          label={field.value}
                                          textcolor={textColor}
                                          backgroundcolor={backgroundColor}
                                          icon={
                                            <FiberManualRecordIcon
                                              style={{
                                                fontSize: "8px",
                                                color: textColor,
                                              }}
                                            />
                                          }
                                          sx={{
                                            fontSize: "11px",
                                            padding: "2px",
                                            height: "unset",
                                            width: "fit-content",
                                          }}
                                        />
                                      );
                                    }}
                                  />
                                );
                              // } else if (row.fieldName === "ocrResult") {
                              //   return (
                              //     <Controller
                              //       control={control}
                              //       name='ocrResult'
                              //       render={({ field }) => {
                              //         if(field.value && field.value.payment_method && field.value.payment_method !=null ){
                              //           setOcrResultPaymentMethod(field.value.payment_method);
                              //           console.log('ShowTable - setOcrResultPaymentMethod',ocrResultPaymentMethod);
                              //         }
                              //         if (
                              //           !field.value || 
                              //           (Array.isArray(field.value.items) &&
                              //             field.value.items?.length === 0) ||
                              //           field.value.items === null ||
                              //           Object.keys(field.value).length === 0
                              //         ) {
                              //           return <p>-</p>;
                              //         }
                              //         return (
                              //           <CustomTable
                              //             rows={field.value.items}
                              //             isEditing={isEditMode}
                              //           />
                              //         );
                              //       }}
                              //     />
                              //   );
                              } else if(row.fieldName === "paymentMethod") {
                                  // console.log("ShowPaymentMethod:",register(
                                  //   row.fieldName || "nullish fallback",
                                  //   {}
                                  // ));
                                  return(
                                    <Controller
                                      control={control}
                                      name={row.fieldName}
                                      render={({ field }) => (
                                          <ShowInput
                                            variant='standard'
                                            inputProps={{ disabled: true }}
                                            type='text'
                                            name={row.fieldName}
                                            value={field.value||ocrResultPaymentMethod}
                                          />
                                      )}
                                    />
                                  )
                              } else if(row.fieldName === "currency") {
                                // console.log("ShowPaymentMethod:",register(
                                //   row.fieldName || "nullish fallback",
                                //   {}
                                // ));
                                return(
                                  <Controller
                                    control={control}
                                    name={row.fieldName}
                                    render={({ field }) => (
                                        <ShowInput
                                          variant='standard'
                                          inputProps={{ disabled: true }}
                                          type='text'
                                          name={row.fieldName}
                                          value={field.value|| currentCurrency}
                                        />
                                    )}
                                  />
                                )
                            } else {                                
                                return (
                                  <ShowInput
                                    {...register(
                                      row.fieldName || "nullish fallback",
                                      {}
                                    )}
                                    variant='standard'
                                    inputProps={{ disabled: true }}
                                    type='text'
                                    name={row.fieldName}
                                    defaultValue={row.defaultValue}
                                  />
                                );
                              }
                            })()}
                          </ShowTableCell>
                        </TableRow>
                      ):
                      ( <TableRow key={index}>
                          <TableCell colSpan={2} sx={{padding:'0px'}}>
                            <Table>
                              <TableBody>
                              <TableRow key={row.label}>
                                <ShowTableCell colSpan={2} sx={{ width: "0" }}>
                                  <ShowLabel>{row.label}</ShowLabel>
                                </ShowTableCell>
                                </TableRow>
                                <TableRow>
                                <ShowTableCell colSpan={2} sx={{ width: "100%"}}>
                                  {(()=>{
                                    return (
                                      <Controller
                                        control={control}
                                        name='ocrResult'
                                        render={({ field }) => {
                                          if(field.value && field.value.payment_method && field.value.payment_method !==null ){
                                                      setOcrResultPaymentMethod(field.value.payment_method);
                                                      // console.log('ShowTable - setOcrResultPaymentMethod',ocrResultPaymentMethod);
                                              }
                                          if (
                                            !field.value || 
                                            (Array.isArray(field.value.items) &&
                                              field.value.items?.length === 0) ||
                                            field.value.items === null ||
                                            Object.keys(field.value).length === 0
                                          ) {
                                            return <p>-</p>;
                                          }
                                          if ( field.value &&
                                            (Array.isArray(field.value.receipts) &&
                                             field.value.receipts?.length > 0)  
                                            ){
                                              setReceiptsError(true);
                                              return <p>只能上載一次交易的收據</p>
                                            //   field.value.receipts.map((item:any)=>(
                                            //     <CustomTable 
                                            //   rows={item.items}
                                            //   categories={categories}
                                            //   isEditing={isEditMode}                                              
                                            //   currentCategory={currentCategory}
                                            //   setCurrentCategory={setCurrentCategory}
                                            //   onChange={(data)=> {
                                            //     // console.log('after changing',data)
                                            //     setLineItems(data)
                                            //   }}
                                            // />
                                            //   )
                                              
                                            //   )
                                            }
                                          console.log('ControllerField:',field);
                                          // setReceiptsError(false);
                                          return (
                                            <CustomTable
                                              rows={field.value.items}
                                              categories={categories}
                                              isEditing={isEditMode}                                              
                                              currentCategory={currentCategory}
                                              setCurrentCategory={setCurrentCategory}
                                              onChange={(data)=> {
                                                // console.log('after changing',data)
                                                setLineItems(data)
                                              }}
                                            />
                                          );
                                        }}
                                      />
                                    );
                                  })()}
                                    </ShowTableCell>
                                
                              </TableRow>
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                      )
                      
                    )}
                    </TableBody>
                  </Table>
                  <EditButton
                    onClick={() => setIsEditMode(true)}
                    startIcon={<EditOutlinedIcon />}
                  >
                    <Typography>Edit Details</Typography>
                  </EditButton>
                  <ReScanButton
                    onClick={() => handleSubmitReScan()}
                    startIcon={<DocumentScannerOutlinedIcon />}
                    disabled={reScanBtn}
                  >
                    <Typography>Re-Scan</Typography>
                  </ReScanButton>
                </>
              ):(<ReceiptErrorBox>只能上載一次交易的收據</ReceiptErrorBox>)
              }
            </ReceiptDetailsContainer>
          </Stack>
         
        </EditLayout>
      </Content>
    </>
  );
};

/*
    DatePicker component is not included in "@refinedev/mui" package.
    To use a <DatePicker> component, you can follow the official documentation for Material UI.

    Docs: https://mui.com/x/react-date-pickers/date-picker/#basic-usage
*/
