import axios from "axios";


export default axios.create({
    baseURL: `${process.env.REACT_APP_DATAPROVIDER_URL}/`,
    headers: {
        "Access-Control-Allow-Headers": "Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization",
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,PUT,PATCH,POST,DELETE",
        "Content-Type": "application/json",
    }
})