// import * as React from 'react';
import React, { useState, ReactElement, useEffect, useRef } from "react";
import Box from '@mui/material/Box';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridColumnMenuProps,
  GridColumnMenu,
  GridRenderCellParams, 
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  TextField,
  Autocomplete,  
} from "@mui/material";
import { randomId } from "@mui/x-data-grid-generator";
import { SaveButton } from "pages/blog-posts/edit";


interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
      }}
    />
  );
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, name: '', unit_price: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <SaveButton
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleClick}
      >
        Add record
      </SaveButton>
    </GridToolbarContainer>
  );
}
interface FullFeaturedCrudGridProps {
    dataRows: GridRowsProp,    
    currentCategory:string,
    setCurrentCategory:any,
    categories:any,
    onChange?: (data: any) => void;
}



export default function FullFeaturedCrudGrid(props: FullFeaturedCrudGridProps) {
  const {dataRows, currentCategory, setCurrentCategory,categories, onChange} = props;
  
  console.log("FullFeaturedCrudGridProps",props);
  const [rows, setRows] = React.useState(dataRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  React.useEffect(() => {
    if (typeof onChange === "function") {
      onChange(rows);
    }
  }, [rows, onChange]);
  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View}});
    console.log('rowModesModel:',rowModesModel);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  function currentCategoryEdit( params: GridRenderCellParams<any, number>){
    // const {dataRows} = params;
    // console.log(dataRows.find((item:any)=>item.id==id).category,props);
    console.log('currentCategoryEdit:',params);
    // return dataRows.find((item:any)=>item.id==id)?dataRows.find((item:any)=>item.id==id).category:null
    return params.value
  }
  
  function CurrentCategoryAutocompleteCell( props: GridRenderCellParams<any, number>){
    // const {value,dataRows} = props;
    const { id, value, field, hasFocus } = props;
    console.log('CurrentCategoryAutocompleteCell:',props);
    const apiRef = useGridApiContext();
    const ref = React.useRef<HTMLElement>(null);

    const handleChange = (event: React.SyntheticEvent, newValue: string | null) => {
      console.log('handleChange:',id, field, newValue);
      apiRef.current.setEditCellValue({ id, field, value: newValue||'' });
    };

    // useEnhancedEffect(() => {
    //   if (hasFocus && ref.current) {
    //     const input = ref.current.querySelector<HTMLInputElement>(
    //       `input[value="${value}"]`,
    //     );
    //     input?.focus();
    //   }
    // }, [hasFocus, value]);
    console.log("currentCategoryAutocomplete:",props);
    // return (<Box>123</Box>)
    return (
      // <Autocomplete
      //   ref={ref}
      //   name="rating"
      //   precision={1}
      //   value={value}
      //   // onChange={handleChange}
      // />
      <Autocomplete
           freeSolo
           disablePortal
           sx={{ width: "120px" }}
           value = { value?.toString()}
          //  value = { value ? value.toString():currentCategory
          //   // currentCategory!==''?currentCategory:dataRows.find((item:any)=>item.id==id)?dataRows.find((item:any)=>item.id==id).category:null
          // }
            inputValue={value?.toString()}
            options={categories}
            onChange = {
              (event,data)=>{
                console.log('AutocompleteOnChange',data);
                // if(data!=null){
                  handleChange(event,data);
                // }
              }
              
              // (event,data) => {
              //             console.log('AutocompleteData:',data);
              //            if(data!=null){
              //               // field.onChange(data);
              //               // setCurrentCategory(data);

              //              }
              //              return data
              //           }
                      }

            onInputChange={(event, newInputValue) => {
              // setCurrentCategory(newInputValue);
              handleChange(event,newInputValue);
            }}
            renderInput={(params) => {
              console.log('AutocompleteParams:',params);
              return(
                <TextField autoFocus {...params} />
              )
            }
          }
      />
    )
  }
  const currentCategoryAutocomplete:GridColDef['renderCell'] = (params) => {
    return <CurrentCategoryAutocompleteCell {...params} />;
  };
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 120,
      editable: true,
      hideable: false,
    },
    {
      field: 'unit_price',
      headerName: 'Unit_price',
      type: 'number',
      width: 100,
      align: 'center',
      headerAlign: 'left',
      editable: true,
      hideable: false
    },
    {
      field: 'qty',
      headerName: 'Qty',
      type: 'number',
      width: 30,
      editable: true,
      hideable: false
    },
    {
      field: 'subtotal',
      headerName: 'Subtotal',
      type: 'number',
      width: 100,
      editable: true,
      hideable: false,
    },
    {
      field: 'discount',
      headerName: 'Discount',
      type: 'number',
      width: 100,
      editable: true,
      hideable: false,
    },
    {
      field: 'brand',
      headerName: 'Brand',
      width: 80,
      editable: true,
      hideable: false,
    },
    {
      field: 'category',
      headerName: 'Category',
      // type: 'singleSelect',
      // valueOptions: [
      //   'Skincare',
      //   'Makeup',
      //   'Beauty device',
      //   'Perfume',
      //   'Beauty tool',
      //   'Hair care',
      //   'Body care',
      //   'Supplement',
      // ],
      renderCell: currentCategoryEdit,
      renderEditCell : currentCategoryAutocomplete,
      width: 120,
      editable: true,
      hideable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 80,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem            
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        // autoHeight
        getRowHeight={() => "auto"}
        editMode="row"
        hideFooter
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
          columnMenu: CustomColumnMenu,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
  );
}